<!-- 审核弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="30%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
 
     
    <a-textarea placeholder="请输入..." :rows="6" />
  
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">拒 绝</el-button>
        <el-button
          type="primary"
          @click="submit"
          :loading="saveType"
          style="background-color: #556bff"
          >通 过</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import imagePreview from "@/pages/views/Acomponents/image-preview/imagePreview";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { imagePreview },
  data() {
    //这里存放数据
    return {
      visible: false,
      saveType: false,
      businessId: "",
      title: "审核说明",


    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {

  },
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.saveType = false;

    },
    // 根据审核意见显示不同的字段
    submit() {
      this.$refs.form.form.validateFields(async (err, value) => {
        console.log(value);
      });
    },
    closeModal() {
      this.visible = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>